<script lang="ts">
  import * as Accordion from "@/components/ui/accordion/index";
  import Button from "@/components/ui/button/button.svelte";
  import Typography from "@/components/ui/typography/typography.svelte";
  import { quintOut } from "svelte/easing";
  import { slide } from "svelte/transition";

  export let faqsList: { title: string; description: string }[] = [];
  export let title = "Frequently <br /> Asked <br /> Questions";
  let showMore = false;
</script>

<div class="container my-20">
  <div class="grid grid-cols-9 gap-8">
    <div class="col-span-9 lg:col-span-4">
      <div class="flex flex-col items-start justify-center gap-1 md:gap-4">
        <Typography
          variant="h2"
          weight="medium"
          class="  max-w-2xl text-5xl	!leading-[56px]">
          {@html title}
        </Typography>
        <Typography
          variant="h4"
          color="muted"
          class="text-left text-2xl !font-normal !leading-[32px]">
          Have a quick question? It's most <br /> probably answered here.
        </Typography>
      </div>
    </div>
    <div class="col-span-9 lg:col-span-5">
      <Accordion.Root class="flex w-full flex-col gap-4">
        {#each faqsList as faq, i}
          {#if i < 5 || showMore}
            <span
              transition:slide={{
                duration: 300,
                easing: quintOut,
                axis: "y"
              }}>
              <Accordion.Item
                value={`item-${i + 1}`}
                class="rounded-2xl border-2 border-dark-gray px-6 py-1">
                <Accordion.Trigger class="hover:no-underline">
                  <Typography
                    as="h3"
                    variant="lead"
                    class="w-[350px] text-left md:w-[565px] lg:w-[400px] xl:w-[565px]"
                    >{faq.title}</Typography>
                </Accordion.Trigger>
                <Accordion.Content>
                  <Typography color="muted" variant="lead">
                    {@html faq.description.replace(/(?:\r\n|\r|\n)/g, "<br>")}
                  </Typography>
                </Accordion.Content>
              </Accordion.Item>
            </span>
          {/if}
        {/each}
      </Accordion.Root>
      {#if faqsList.length > 5}
        <Button
          on:click={() => (showMore = !showMore)}
          variant="secondary"
          class="my-4 w-full p-4">Show {showMore ? "less" : "more"}</Button>
      {/if}
    </div>
  </div>
</div>
